import {
  getRequest,
  postRequest,
  putRequest,
  deleteReqest,
  patchRequest,
} from "./MainBaseServices";

import axios from "axios";

import { configURL } from "./configuration";

export const AddNewStockService = async (payload) => {
  try {
    let response = await postRequest(configURL.add_new_stock, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:17 ~ AddNewStockService ~ error:",
      error
    );
    return error;
  }
};

export const SearchStockService = async (payload) => {
  try {
    let response = await postRequest(configURL.search_stock, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:31 ~ SearchStockService ~ error:",
      error
    );
    return error;
  }
};

export const UpdateStockServices = async (payload) => {
  try {
    let response = await postRequest(configURL.update_stock, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:43 ~ UpdateStockServices ~ error:",
      error
    );
    return error;
  }
};

export const AddStockImagesService = async (payload) => {
  try {
    let response = await postRequest(configURL.add_stock_images, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:56 ~ AddStockImagesService ~ error:",
      error
    );
    return error;
  }
};

export const RemoveStockImageService = async (id, img) => {
  try {
    let response = await getRequest(
      `${configURL.remove_stock_image}/${id}/${img}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:69 ~ RemoveStockImageService ~ error:",
      error
    );
    return error;
  }
};

export const ChangeStockStatusService = async (id, status) => {
  try {
    let response = await getRequest(
      `${configURL.change_stock_status}/${id}/${status}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:85 ~ ChangeStockStatusService ~ error:",
      error
    );
    return error;
  }
};

export const GetStockByIdService = async (id) => {
  try {
    let response = await getRequest(`${configURL.get_stock_by_id}/${id}/`);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:99 ~ GetStockByIdService ~ error:",
      error
    );
    return error;
  }
};

export const GetSubcategoryByCategoryId = async (id) => {
  try {
    let response = await getRequest(
      `${configURL.get_subcategoriesByid}/${id}/`
    );

    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:113 ~ GetSubcategoryByCategoryId ~ error:",
      error
    );
    return error;
  }
};

export const GetAllGroupsForCategoriesService = async (id) => {
  try {
    let response = await getRequest(
      `${configURL.getshowgroupsforcategories}/${id}/`
    );
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:129 ~ GetAllGroupsForCategoriesService ~ error:",
      error
    );
    return error;
  }
};

export const CreatePrintedCatalogService = async (payload) => {
  try {
    let repsonse = await postRequest(configURL.get_printed_catalog, payload);

    return repsonse;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:143 ~ CreatePrintedCatalogService ~ error:",
      error
    );
    return error;
  }
};

export const ExportDataImagesService = async (payload) => {
  try {
    let response = await postRequest(configURL.export_data_images, payload);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:159 ~ ExportDataImagesService ~ error:",
      error
    );
    return error;
  }
};

export const CompleteExportService = async () => {
  try {
    let response = await postRequest(configURL.complete_export);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:159 ~ ExportDataImagesService ~ error:",
      error
    );
    return error;
  }
};

export const GetExportedStockDateService = async () => {
  try {
    let response = await getRequest(configURL.get_exported_stock_date);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:184 ~ GetExportedStockDateService ~ error:",
      error
    );
    return error;
  }
};

export const GetAllStockLocationsService = async () => {
  try {
    let response = await getRequest(configURL.get_all_stock_locations);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:184 ~ GetExportedStockDateService ~ error:",
      error
    );
    return error;
  }
};

export const GetTotalActiveStockService = async () => {
  try {
    let response = await getRequest(configURL.get_all_total_active_stocks);
    return response;
  } catch (error) {
    console.log(
      "🚀 ~ file: StockServices.js:184 ~ GetExportedStockDateService ~ error:",
      error
    );
    return error;
  }
};
