import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { PrintPage } from "./PrintPage";

export const FunctionalComponentWithHook = ({ rows, columns, data }) => {
  const componentRef = useRef(null);
  // const column_row_ref = useRef({});

  const onBeforeGetContentResolve = useRef(null);

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("old text");

  // const rows_columns = () => {
  //   let row = new Array(7).fill(0);
  //   let columns = new Array(3).fill(0);

  //   let value = 0;

  //   row.forEach((val, index) => {
  //     columns.forEach((v, ind) => {
  //       let prop = `${index + 1}_${ind + 1}`;
  //       column_row_ref.current[prop] = value;
  //       value++;
  //     });
  //   });
  // };

  // useEffect(() => {
  //   rows_columns();
  // }, []);

  const handleAfterPrint = useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");

        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Pennymead.com",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, data]);

  return (
    <div>
      <button
        type="button"
        onClick={handlePrint}
        className="btn btn-primary rounded-0 my-2"
      >
        {loading ? "Generating..." : "Generate Label"}
      </button>
      <div className="d-none">
        <PrintPage
          ref={componentRef}
          data={data}
          rows={rows}
          columns={columns}
          // column_row_ref={column_row_ref.current}
        />
      </div>
    </div>
  );
};
