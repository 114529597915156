import React, { useEffect, useMemo, useState } from "react";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import Swal from "sweetalert2";
import "./styles.scss";
import { GlobalFilter } from "../../../../Utils/Table/GlobalFilter";
import Data from "./data.json";

import { useNavigate, useLocation } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { Images } from "../../../../Assets/Images/imageslist";
import {
  DeleteAdminService,
  EditAdminService,
  MakeSuperAdminService,
} from "../../../../Services/AdminServices";
import Modal from "react-bootstrap/Modal";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { configURL } from "../../../../Services/configuration";

export const AdminTable = ({ admins_list, getalladminslistsrv }) => {
  const { getRequest, postRequest } = ApiResponseHook();

  const [adminDeleteData, setAdminDeleteData] = useState({});

  const [makeSuperAdminData, setMakeSuperAdminData] = useState({});

  const [isSystemChecked, setIsSystemChecked] = useState(false);
  const [isSalesChecked, setIsSalesChecked] = useState(false);

  // edit modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editAdminFormData, setEditAdminFormData] = useState({
    id: "",
    username: "",
    name: "",
    email: "",
    system: "",
    sales: "",
  });

  const location = useLocation();

  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Real Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: (d) => {
          if (d.email === null) {
            return `No Email`;
          } else {
            return d.email;
          }
        },
      },
      {
        Header: "Username",
        accessor: "username",
      },

      {
        Header: "Super Admin",
        accessor: (d) => {
          if (d.super === "1") {
            return `Yes`;
          } else {
            return `No`;
          }
        },
      },
      {
        Header: "System Admin",

        accessor: (d) => {
          if (d.system === "1") {
            return `Yes`;
          } else {
            return `No`;
          }
        },
      },
      {
        Header: "Sales Admin",
        // accessor: "sales",
        accessor: (d) => {
          if (d.sales === "1") {
            return `Yes`;
          } else {
            return `No`;
          }
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: (tableProps) => (
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn mx-1 editbutton"
              // data-toggle="modal"
              // data-target="#editModal"
              // onClick={() => editAdmin(tableProps.cell.row.original)}
              onClick={() => editAdmin(tableProps.cell.row.original)}
            >
              Edit
            </button>
            {/* <button
              className="btn mx-1 deleteButton"
              data-toggle="modal"
              data-target="#deleteModal"
              onClick={() => deleteAdmin(tableProps.cell.row.original)}
            >
              Delete
            </button> */}

            {tableProps.cell.row.original.super === "0" && (
              <>
                <button
                  className="btn mx-1 editbutton"
                  data-toggle="modal"
                  data-target="#makesuperadmin"
                  onClick={() => makeSuperAdmin(tableProps.cell.row.original)}
                >
                  Make Super Admin
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  // const data = useMemo(() => props.data);
  const data = useMemo(() => admins_list);

  // create a table instance
  const {
    tableInstance,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageSize, pageIndex } = state;

  const clearFormState = () => {
    handleClose();
    setEditAdminFormData({
      ...editAdminFormData,
      id: "",
      username: "",
      name: "",
      email: "",
      system: "",
      sales: "",
    });
  };

  const editAdmin = (data) => {
    handleShow();
    if (data.system === "1") {
      setIsSystemChecked(true);
    } else {
      setIsSystemChecked(false);
    }

    if (data.sales === "1") {
      setIsSalesChecked(true);
    } else {
      setIsSalesChecked(false);
    }

    setEditAdminFormData({
      ...editAdminFormData,
      id: data?.id || "",
      email: data?.email || "",
      name: data?.name || "",
      username: data?.username || "",
      sales: data?.sales || "",
      system: data?.system || "",
    });
  };

  const deleteAdmin = async (data) => {
    handleClose();
    setAdminDeleteData(data);
  };

  const makeSuperAdmin = async (data) => {
    setMakeSuperAdminData(data);
  };

  const makeSuperAdminFun = async () => {
    let paylaod = {
      id: makeSuperAdminData.id,
    };

    // let res = await MakeSuperAdminService(paylaod);
    let res = await postRequest(configURL.make_super_admin, paylaod);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Changed to Super Admin`,
      });
      document.getElementById("makesuperadminModal").click();
      getalladminslistsrv();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const deleteAdminFun = async () => {
    let payload = {
      id: editAdminFormData.id,
    };

    // let res = await DeleteAdminService(payload);

    let res = await postRequest(configURL.delete_admin, payload);

    if (res.status === 200 || res.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Deleted the admin successfully!`,
      });

      document.getElementById("deleteAdminModal").click();
      getalladminslistsrv();
    } else if (
      res.response.status === 400 ||
      res.response.status === 404 ||
      res.response.status === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error! ${res.response?.data?.message}`,
      });
      // console.log("error------>", res);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error!`,
      });
      // console.log("error", res);
    }
  };

  const editAdminDataFun = async () => {
    let payload = {
      username: editAdminFormData.username,
      name: editAdminFormData.name,
      email: editAdminFormData.email,
      id: editAdminFormData.id,
      system: editAdminFormData.system,
      sales: editAdminFormData.sales,
    };

    // let res = await EditAdminService(payload);

    let res = await postRequest(`${configURL.edit_admin}/`, payload);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated Successfully!`,
      });
      getalladminslistsrv();
      document.getElementById("closeEditAdminModal").click();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      let errorObj = res?.response?.data?.error;

      const firstValue = errorObj[Object.keys(errorObj)[0]];

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error : ${firstValue || res?.response?.data?.message}`,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error : ${res?.response?.data?.message}`,
      });
      // console.log("error", res);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let obj = {};
    obj[name] = value;
    setEditAdminFormData({ ...editAdminFormData, ...obj });
  };

  const handleCheckboxChange = (e) => {
    let { name, checked } = e.target;
    let isChecked = e.target.checked;

    if (name === "sales") {
      setIsSalesChecked(checked);
      isChecked
        ? setEditAdminFormData({ ...editAdminFormData, sales: "1" })
        : setEditAdminFormData({ ...editAdminFormData, sales: "0" });
    }

    if (name === "system") {
      setIsSystemChecked(checked);
      isChecked
        ? setEditAdminFormData({ ...editAdminFormData, system: "1" })
        : setEditAdminFormData({ ...editAdminFormData, system: "0" });
    }
  };

  return (
    <>
      <div className="mt-3 mb-3">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div>
        <table {...getTableProps()} className="mt-3">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            src={Images.downarrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        ) : (
                          <img
                            src={Images.uparrow}
                            alt=""
                            className="arrowsIcon"
                          />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {Data.length === 0 ? (
            <tbody>
              <tr>No orders</tr>
            </tbody>
          ) : (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                        // onClick={() => gotoOrderDetails(row.original)}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="d-flex align-items-center mb-3">
          <div>
            <select
              value={pageSize}
              className="selectTag mb-3"
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              <option value="" disabled>
                Select
              </option>
              {[5, 10, 15, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="w-100 text-center mt-3 mb-1">
            <button
              className="mx-1 skipToBtn"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="mx-1 actionBtn"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <span className="mx-2 pageNumber">
              {/* Page{" "} */}
              <strong>
                {pageIndex + 1}
                {/* of {pageOptions.length} */}
              </strong>{" "}
            </span>
            <button
              className="mx-1 actionBtn"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="skipToBtn"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>

      {/* Delete Modal */}

      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Delete Admin
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete the admin?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                id="deleteAdminModal"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn deleteButton"
                onClick={deleteAdminFun}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*  new edit modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> Edit Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="editFormDiv">
            <div className="d-flex flex-column">
              <label htmlFor="">Name</label>

              <input
                type="text"
                id="name"
                name="name"
                className="inputTag"
                value={editAdminFormData?.name || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">Username</label>
              <input
                type="text"
                name="username"
                id="username"
                value={editAdminFormData?.username || ""}
                onChange={handleInputChange}
                className="inputTag"
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                value={editAdminFormData?.email || ""}
                onChange={handleInputChange}
                className="inputTag"
              />
            </div>

            <div>
              <div class="form-check pl-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  name="system"
                  checked={isSystemChecked}
                  onChange={handleCheckboxChange}
                  id="defaultSystemCheck"
                />
                <label class="form-check-label" for="defaultSystemCheck">
                  System Admin
                </label>
              </div>
            </div>
            <div>
              <div class="form-check pl-0 d-flex align-items-center">
                <input
                  type="checkbox"
                  name="sales"
                  checked={isSalesChecked}
                  onChange={handleCheckboxChange}
                  id="defaultSalesCheck"
                />
                <label class="form-check-label" for="defaultSalesCheck">
                  Sales Admin
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary rounded-0"
            id="closeEditAdminModal"
            data-dismiss="modal"
            onClick={clearFormState}
          >
            Close
          </button>

          <button
            type="button"
            className="btn editbutton"
            onClick={editAdminDataFun}
          >
            Save
          </button>
          <button
            className="btn mx-1 deleteButton"
            data-toggle="modal"
            data-target="#deleteModal"
            onClick={() => deleteAdmin()}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Make super admin */}

      <div
        className="modal fade"
        id="makesuperadmin"
        tabindex="-1"
        aria-labelledby="makesuperadminLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="makesuperadminLabel">
                Make super admin
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure you make the user as a super admin?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                id="makesuperadminModal"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn editbutton"
                onClick={makeSuperAdminFun}
              >
                Make super admin
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
