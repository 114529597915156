// import React, { useEffect, useState } from "react";
// import './PrintPage.scss'

// export const PrintPage = React.forwardRef((props, ref) => {
//   const { rows, columns, data, column_row_ref } = props;
//   let row = rows;
//   let column = columns;

//   if (row < 1) {
//     row = 1;
//   }

//   if (row > 7) {
//     row = 7;
//   }

//   if (column < 1) {
//     column = 1;
//   }

//   if (column > 3) {
//     column = 3;
//   }


//   let display_positions = new Array(21).fill("");
//   // let display_positions = new Array(21).fill(data); 
//   let property = [`${row}_${column}`];
//   let replace_index = column_row_ref[property];

//   display_positions.splice(replace_index, 1, data);


//   return (
//     <div
//       ref={ref}
//       className="print-page"
//       style={{
//         padding: "55px 24px",
//       }}>
//       <div
//         className="row m-0 justify-content-between" style={{ columnGap: "10px" }}
//       >
//         {display_positions.map((val) => (
//           <div
//             className="col-4"
//             style={{
//               // border: "1px solid blue",
//               flex: "0 0 32.2%",
//               width: "241.31px",
//               height: "144.60px",
//               padding: 0,
//             }}
//           >
//             {val && Object.keys(data).length > 0 && (
//               <div style={{
//                 display: "flex", alignItems: "center",
//                 borderRadius: 12,
//                 paddingLeft: 10, paddingRight: 5, width: "100%", height: "100%"
//               }}>
                // <div>
                //   {data?.title !== "" &&
                //     data.forename !== "" &&
                //     data.name !== "" && (
                //       <>
                //         <div>
                //           <span className="print_address"></span>
                //           <span className="print_address">{data?.title} </span>{" "}
                //           <span className="print_address">{data?.forename}</span>{" "}
                //           <span className="print_address"> {data?.name}</span>
                //         </div>
                //       </>
                //     )}
                //   {data?.title === "" &&
                //     data?.forename === "" &&
                //     data.name !== "" && (
                //       <>
                //         <p className="print_address">{data?.name}</p>
                //       </>
                //     )}

                //   {data.address1 && (
                //     <p className="print_address">{data.address1}</p>
                //   )}
                //   {data.address2 && (
                //     <p className="print_address">{data.address2}</p>
                //   )}
                //   {data.town && <p className="print_address">{data.town}</p>}
                //   {data.county && <p className="print_address">{data?.county}</p>}
                //   {data.state && <p className="print_address">{data.state}</p>}

                //   {data.postcode && (
                //     <p className="print_address">{data.postcode}</p>
                //   )}
                //   {data.country && (
                //     <p className="print_address">{data.country_name}</p>
                //   )}
                // </div>
//               </div>
//             )}
//           </div>
//         ))
//         }
//       </div>
//     </div>
//   );
// });


import React from "react";
import "./PrintPage.scss";

export const PrintPage = React.forwardRef((props, ref) => {
  const { rows, columns, data } = props;
  const topPosition = columns;  
  const leftPosition = rows;

  return (
    <div
      ref={ref}
      className="print-page"
      style={{
        padding: "0",
        position: "relative",
        // width: "100%", 
        // height: "100%", 
      }}
    >
      {/* Position the address box */}
      {data && Object.keys(data).length > 0 && (
        <div
          className="address-box"
          style={{
            position: "absolute",
            top: `${topPosition}cm`,
            left: `${leftPosition}cm`,
            width: "240px",
            height: "144px",
            display:"flex",
            alignItems:"center",
            boxSizing: "border-box",
            // background:"red"
          }}
        >
          <div>
                  {data?.title !== "" &&
                    data.forename !== "" &&
                    data.name !== "" && (
                      <>
                        <div>
                          <span className="print_address"></span>
                          <span className="print_address">{data?.title} </span>{" "}
                          <span className="print_address">{data?.forename}</span>{" "}
                          <span className="print_address"> {data?.name}</span>
                        </div>
                      </>
                    )}
                  {data?.title === "" &&
                    data?.forename === "" &&
                    data.name !== "" && (
                      <>
                        <p className="print_address">{data?.name}</p>
                      </>
                    )}

                  {data.address1 && (
                    <p className="print_address">{data.address1}</p>
                  )}
                  {data.address2 && (
                    <p className="print_address">{data.address2}</p>
                  )}
                  {data.town && <p className="print_address">{data.town}</p>}
                  {data.county && <p className="print_address">{data?.county}</p>}
                  {data.state && <p className="print_address">{data.state}</p>}

                  {data.postcode && (
                    <p className="print_address">{data.postcode}</p>
                  )}
                  {data.country && (
                    <p className="print_address">{data.country_name}</p>
                  )}
            </div>
        </div>
      )}
    </div>
  );
});
