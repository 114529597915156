import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { GetAllCountriesService } from "../../../../Services/CommonServices";

import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import Swal from "sweetalert2";
import "./styles.scss";
import { TransactionTable } from "../OrderTable/TransactionTable";
import BounceLoader from "react-spinners/BounceLoader";
import { FunctionalComponentWithHook } from "../../CustomerManagement/ViewCustomer/FunctionalComponentWithHook";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { Images } from "../../../../Assets/Images/imageslist";

// !SPINNERS

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const ListingOrdersComponent = ({
  testdata,
  data,
  getAllDataForTheSelectedFilter,
}) => {
  const { getRequest, postRequest } = ApiResponseHook();

  const location = useLocation();
  const navigate = useNavigate();
  // let [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [allCountries, setAllCountries] = useState([]);

  const [editAddress, setEditAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });

  const [editError, setEditError] = useState({
    address1: false,
    address2: false,
    city: false,
    county: false,
    postcode: false,
    country: false,
    orderno: false,
  });
  const [editErrorMsg, setEditErrorMsg] = useState({
    address1: "",
    address2: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });

  const [updateOrder, setUpdateOrder] = useState({});

  const [postageError, setPostageError] = useState(false);
  const [postageIndex, setPostageIndex] = useState(null);

  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    setIsEdit(false)
  }, [data]);

  const [rows, setRows] = useState(1);
  const [columns, setColumns] = useState(1);

  const handleRowChange = (e) => {
    const value = e.target.value;
    setRows(isNaN(value) ? null : value);
  };

  const handleColumnChange = (e) => {
    const value =e.target.value;
    setColumns(isNaN(value) ? null : value);
  };

  const getAllCountries = async () => {
    let res = await GetAllCountriesService();

    if (res?.status === 200 || res?.status === 201) {
      setAllCountries(res?.data?.data);
    } else {
      // console.log("error getting all the countries", res);
    }
  };

  const handleAddressChange = (e) => {
    let { name, value } = e.target;

    if (name === "address1") {
      setEditError({
        ...editError,
        address1: false,
      });
      setEditAddress({
        ...editAddress,
        address1: value,
      });
    }
    if (name === "address2") {
      setEditError({
        ...editError,
        address2: false,
      });
      setEditAddress({
        ...editAddress,
        address2: value,
      });
    }
    if (name === "city") {
      setEditError({
        ...editError,
        city: false,
      });
      setEditAddress({
        ...editAddress,
        city: value,
      });
    }
    if (name === "county") {
      // setEditError({
      //   ...editError,
      //   county: false,
      // });
      setEditAddress({
        ...editAddress,
        county: value,
      });
    }
    if (name === "postcode") {
      setEditError({
        ...editError,
        postcode: false,
      });
      setEditAddress({
        ...editAddress,
        postcode: value,
      });
    }
    if (name === "country") {
      setEditError({
        ...editError,
        country: false,
      });
      setEditAddress({
        ...editAddress,
        country: value,
      });
    }
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault();
    }
  }

  const handleChange = (e, index) => {
    let { name, value } = e.target;

    if (name === "postage") {
      const inputValue = parseFloat(value);
      if (isNaN(inputValue) || inputValue < 0) {
        e.target.value = ""; // Clear the input
      } else {
        setUpdateOrder({
          ...updateOrder,
          [`postage${index}`]: value || "0",
        });
      }
    }
    if (name === "pricechange") {
      const inputValue = value;
      const numberPattern = /^-?\d*\.?\d*$/;
      if (!numberPattern.test(inputValue)) {
        // Prevent the input
        e.preventDefault();
      } else {
        setUpdateOrder({
          ...updateOrder,
          [`pricechange${index}`]: value || "0",
        });
      }
    }
    if (name === "note") {
      setUpdateOrder({
        ...updateOrder,
        [`note${index}`]: value,
      });
    }
  };

  // get particular orderno price change values
  const getPostageValue = (data, number, keyName) => {
    const key = keyName + number;
    return data[key];
  };

  const addAdditionalDetails = async (data) => {
    let payload = {
      postage_charge:
        getPostageValue(updateOrder, data.orderno, "postage") || data.postage,
      adjustment:
        getPostageValue(updateOrder, data.orderno, "pricechange") ||
        data.pricechange,
      note: getPostageValue(updateOrder, data.orderno, "note") || "",
      orderno: data.orderno,
    };

    // setLoading(true);
    // let res = await AddAdditionalOrderDetailsService(payload);
    let res = await postRequest(configURL.add_additional_orderDeatils, payload);

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      getAllDataForTheSelectedFilter();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the price successfully`,
      });
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const handleEditFunctionality = (index, item) => {
    setEditedIndex(index);
    setIsEdit(true);

    setEditAddress({
      address1: item?.address1,
      address2: item?.address2,
      city: item?.town,
      country: item?.country,
      county: item?.county,
      orderno: item?.orderno,
      postcode: item?.postcode,
    });
  };

  const sendInvoiceFun = async (data) => {
    // // setLoading(true);
    // let res = await SendOrderInvoiceService(data?.orderno);
    let res = await getRequest(
      `${configURL.send_order_invoice}/${data?.orderno}/`
    );

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Invoice sent successfully!`,
      });
      getAllDataForTheSelectedFilter();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const markPaymentReceived = async (data) => {
    // setLoading(true);
    // let res = await MarkPaymentReceivedService(data?.orderno);
    let res = await getRequest(
      `${configURL.mark_payment_received}/${data?.orderno}/`
    );

    if (res?.status === 201 || res?.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Marked Payment Received`,
      });

      getAllDataForTheSelectedFilter();
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const sendPaymentReminderFun = async (data) => {
    // setLoading(true);

    // let res = await SendPaymentReminderService(data?.orderno);
    let res = await getRequest(
      `${configURL.send_payment_reminder}/${data?.orderno}/`
    );

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Remainder sent successfully!`,
      });
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const generateInvoicePage = (data) => {
    let arr = [];
    arr.push(data);
    navigate(`/${RouteStrings.orderInvoicePage}`, {
      state: { data: { order: data.stock_details, customer: arr } },
    });
  };

  const [deleteOrderno, setDeleteOrderno] = useState("");
  const [selectedOrderToDelete, setSelectedOrderToDelete] = useState({});

  const deleteCustomerFun = async () => {
    // let response = await DeleteOrderService(deleteOrderno);
    let response = await getRequest(
      `${configURL.delete_order}/${deleteOrderno}/`
    );

    if (response?.status === 200 || response?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${response?.data?.Message || `Order Deleted Successfully`}`,
      });
      document.getElementById("deleteModal").click();
      getAllDataForTheSelectedFilter();
    } else if (
      response?.response?.status === 400 ||
      response?.response?.status === 404 ||
      response?.response?.status === 401
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.data?.message}`,
      });

      // console.log("error", response);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${response?.response?.data?.message}`,
      });

      // console.log("error", response);
    }
  };

  const [deliveryStatus, setDeiveryStatus] = useState("");
  const [deliveryNote, setDeliveryNote] = useState({});

  const handleDeliveryStatus = (e, order_status) => {
    // if (
    //   order_status === "" ||
    //   order_status === undefined ||
    //   order_status === null
    // ) {
    setDeiveryStatus(e.target.value);
    // } else {
    //   setDeiveryStatus(order_status);
    // }
  };

  const handleDeliveryNote = (e, orderno) => {
    let { name, value } = e.target;
    if (value) {
      setDeliveryNote({
        ...deliveryNote,
        [`delivery${orderno}`]: value || "0",
      });
    }
  };

  const updateDeliveryStatus = async (data) => {
    // let paylaod = {
    //   order_status: deliveryStatus,
    //   order_note: getPostageValue(deliveryNote, data.orderno, "delivery") || "",
    //   orderno: data?.orderno,
    // };

    let _order_note = "";
    let _package_status = "";

    let pakagenote_data =
      getPostageValue(deliveryNote, data.orderno, "delivery") || "";

    if (deliveryStatus === "") {
      _package_status = data?.order_status;
    } else {
      _package_status = deliveryStatus;
    }

    _order_note = pakagenote_data;

    let payload = {
      order_status: _package_status,
      order_note: getPostageValue(deliveryNote, data.orderno, "delivery") || "",
      orderno: data?.orderno,
    };

    if (
      (payload.order_status === "" ||
        payload.order_status === undefined ||
        payload.order_status === null) &&
      (payload.order_note === "" ||
        payload.order_note === undefined ||
        payload.order_note === null)
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please enter the status and the note`,
      });
    } else if (
      payload.order_note === "" ||
      payload.order_note === undefined ||
      payload.order_note === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please enter the note`,
      });
    } else if (
      payload.order_status === "" ||
      payload.order_status === undefined ||
      payload.order_status === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please select the status`,
      });
    } else {
      let res = await postRequest(configURL.changeunpackedorderstatus, payload);

      if (res?.status === 200 || res?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Delivery Status Updated`,
        });

        getAllDataForTheSelectedFilter();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${res?.response?.data?.message} || error updating the delivery status`,
        });
      }
    }
  };

  const updateAddressfun = async (data) => {
    if (
      editAddress.address1 === "" &&
      editAddress.city === "" &&
      editAddress.country === "" &&
      // editAddress.county === "" &&
      editAddress.postcode === ""
    ) {
      setEditError({
        ...editError,
        address1: true,
        city: true,
        country: true,
        // county: true,
        postcode: true,
      });

      setEditErrorMsg({
        address1: "Please enter address 1",
        city: "Please enter city",
        country: "Please enter country",
        // county: "Please enter county",
        postcode: "Please enter postcode",
      });
    } else if (editAddress.address1 === "") {
      setEditError({
        editError,
        address1: true,
      });
      setEditErrorMsg({
        address1: "Please enter address 1",
      });
    } else if (editAddress.city === "") {
      setEditError({
        editError,
        city: true,
      });
      setEditErrorMsg({
        city: "Please enter town",
      });
    }
    // else if (editAddress.county === "") {
    //   setEditError({
    //     editError,
    //     county: true,
    //   });
    //   setEditErrorMsg({
    //     county: "Please enter county",
    //   });
    // }
    else if (editAddress.country === "") {
      setEditError({
        editError,
        country: true,
      });
      setEditErrorMsg({
        country: "Please enter country",
      });
    } else if (editAddress.postcode === "") {
      setEditError({
        editError,
        postcode: true,
      });
      setEditErrorMsg({
        postcode: "Please enter postcode",
      });
    } else {
      // setLoading(true);
      // let res = await EditCustomerAddressService(editAddress);
      let res = await postRequest(configURL.edit_address, editAddress);

      if (res?.status === 201 || res?.status === 200) {
        // setLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the address successfully`,
        });
        getAllDataForTheSelectedFilter();
        setIsEdit(false);
      } else if (
        res?.response?.status === 400 ||
        res?.response?.status === 401 ||
        res?.response?.status === 404 ||
        res?.response?.status === 500
      ) {
        // setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // setLoading(false);
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const handleCombineClick = (event, item) => {
    if (event) {
      setSelectedOrders([...selectedOrders, item.orderno]);
    } else {
      if (selectedOrders.includes(item.orderno)) {
        setSelectedOrders(
          selectedOrders.filter((order) => order !== item.orderno)
        );
      }
    }
  };

  const combineOrdersFunction = async () => {
    let payload = {
      ordernos: selectedOrders.join(","),
    };

    // let res = await CombineOrderService(payload);
    let res = await postRequest(configURL.combineOrders, payload);

    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Combined Orders Successfully!`,
      });
      setSelectedOrders([]);
      getAllDataForTheSelectedFilter();
    } else {
      setSelectedOrders([]);
      Swal.fire({
        icon: "Error",
        text: `Error : ${res?.response?.data?.message}`,
      });
    }
  };

  const removeOrder = (orderToRemove) => {
    const updatedOrders = selectedOrders.filter(
      (order) => order !== orderToRemove
    );

    setSelectedOrders(updatedOrders);
  };

  const getOnlyDateFromDateTime = (date_) => {
    const parts = date_.split(" ");

    const datePart = parts[0];

    return datePart;
  };

  const isUpdatePriceDisabled = (price, postage) => {
    if (price === null) {
      return "true";
    } else if (postage === null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {data?.length === 0 && (
        <>
          <strong>No orders found</strong>
        </>
      )}

      {selectedOrders?.length > 0 && (
        <>
          <div className="sticky-button-list-orders">
            <div className="">
              {selectedOrders.length > 1 && (
                <p>
                  <strong>The selected orders are : </strong>
                </p>
              )}
              {selectedOrders?.map((order) => {
                return (
                  <>
                    <div className="d-flex align-items-center p-1">
                      <p className="mb-0">
                        <strong>Order No : </strong>
                        {order}
                      </p>

                      <img
                        src={Images.deleteIcon}
                        alt="deleteIcon"
                        className="deleteOrderIcon mx-2"
                        onClick={() => removeOrder(order)}
                      />
                    </div>
                  </>
                );
              })}
              {selectedOrders.length < 2 && (
                <p className="mb-0 text-danger">
                  <strong>
                    Need to select more than one order to combine.
                  </strong>
                </p>
              )}

              <button
                className="btn btn-primary rounded-0 my-2"
                id="combineordersbutton"
                onClick={combineOrdersFunction}
                style={{ zIndex: "9999" }}
                disabled={selectedOrders.length < 2 ? true : false}
              >
                Combine Orders
              </button>
            </div>
          </div>
        </>
      )}

      <>
        {data?.map((item, index) => {
          if (item.status == "1") {
            return (
              <>
                <div key={index} className="my-4">
                  <div className="card p-3 order_card">
                    <div className="ordernumber mb-2 d-flex align-items-center justify-content-between">
                      <>
                        {item?.invoiced === "0" && item?.transtatus === "0" && (
                          <div>
                            <div className="px-4">
                              <Form>
                                <div className="mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    id={`checkbox-${index}`}
                                    name={`checkbox-${index}`}
                                    onClick={(e) =>
                                      handleCombineClick(e.target.checked, item)
                                    }
                                    checked={
                                      selectedOrders.includes(item.orderno)
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Form>
                            </div>

                            <div className="d-flex mx-4 flex-column">
                              <h6 className="mr-2">
                                Order Number : {item?.orderno}
                              </h6>
                              {/* <h6>Order Date : {item?.ordate || "---"}</h6> */}
                              <h6>
                                Order Date :{" "}
                                {getOnlyDateFromDateTime(item?.ordate) || "---"}
                              </h6>
                            </div>
                          </div>
                        )}

                        {item?.invoiced === "1" && item?.transtatus === "0" && (
                          <div className="d-flex flex-column test__">
                            <h6 className="mr-2">
                              Order Number : {item?.orderno}
                            </h6>
                            <h6>
                              Order Date :{/* {item?.ordate || "---"} */}
                              {getOnlyDateFromDateTime(item?.ordate) || "---"}
                            </h6>
                          </div>
                        )}

                        {item?.invoiced === "1" &&
                          item?.transtatus === "1" &&
                          (item.order_status === null ||
                            item.order_status === "" ||
                            item.order_status === "packed" ||
                            item.order_status === "dispatch") && (
                            <div className="d-flex flex-column">
                              <h6 className="mr-2">
                                Order Number : {item?.orderno}
                              </h6>
                              <h6>
                                Order Date :{/* {item?.ordate || "---"} */}
                                {getOnlyDateFromDateTime(item?.ordate) || "---"}
                              </h6>
                            </div>
                          )}

                        {item?.invoiced === "1" &&
                          item?.transtatus === "1" &&
                          item.order_status === "delivered" && (
                            <div className="d-flex flex-column">
                              <h6 className="mr-2">
                                Order Number : {item?.orderno}
                              </h6>
                              <h6>
                                Order Date :{/* {item?.ordate || "---"} */}
                                {getOnlyDateFromDateTime(item?.ordate) || "---"}
                              </h6>
                            </div>
                          )}
                      </>

                      <div>
                        {item?.invoiced === "0" && item?.transtatus === "0" && (
                          <>
                            <div className="my-2">
                              {item?.status === "1" && (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      className="btn btn-danger rounded-0"
                                      id="deleteorderbutton"
                                      onClick={() => {
                                        setDeleteOrderno(item?.orderno);
                                        setSelectedOrderToDelete(item);
                                      }}
                                      data-toggle="modal"
                                      data-target="#deleteModal"
                                    >
                                      Delete Order
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {item?.invoiced === "1" && item?.transtatus === "0" && (
                          <>
                            <div className="my-2">
                              {item?.status === "1" && (
                                <>
                                  <div className="d-flex justify-content-end">
                                    <button
                                      className="btn btn-danger rounded-0"
                                      id="deleteorderbutton"
                                      onClick={() => {
                                        setDeleteOrderno(item?.orderno);
                                        setSelectedOrderToDelete(item);
                                      }}
                                      data-toggle="modal"
                                      data-target="#deleteModal"
                                    >
                                      Delete Order
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="mb-2">
                      {item?.stock_details?.map((stock) => {
                        return (
                          <>
                            <div
                              className="row"
                              style={{ borderBottom: "1px solid #00000020" }}
                            >
                              <div className="col-md-4">
                                <div className="image_div">
                                  <img
                                    src={stock?.stockimage[0]}
                                    alt={`${stock?.author} ${stock?.title}`}
                                    className="stockimage my-2"
                                  />
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div>
                                  <p>{stock?.author}</p>
                                  <p>{stock?.title}</p>
                                  <p>{stock?.description}</p>
                                  <p>{stock?.stockref}</p>
                                  <p>{stock?.location}</p>
                                  <p>£{stock?.price}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>

                    {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! the customer detials here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

                    <div className="customerdetails">
                      <h6>Customer Details</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name</td>
                            <td>{item?.name || "--"}</td>
                          </tr>
                          <tr>
                            <td>Customer Number</td>
                            <td>{item?.custno || "--"}</td>
                          </tr>
                          <tr>
                            <td>
                              Address Line 1{" "}
                              <span className="text-danger">&#42;</span>
                            </td>
                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    defaultValue={item?.address1}
                                    onChange={handleAddressChange}
                                    placeholder="Enter the address1"
                                    className="inputTag w-75"
                                  />
                                  {editError.address1 &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.address1}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.address1 || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr key={index}>
                            <td>Address Line 2</td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    defaultValue={item?.address2}
                                    placeholder="Enter the address2"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.address2 &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.address2}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.address2 || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Town/City{" "}
                              <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    value={editAddress?.city}
                                    placeholder="Enter the town"
                                    onKeyDown={validateInput}
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.city && index === editedIndex && (
                                    <>
                                      <p className="mb-0 text-danger">
                                        {editErrorMsg.city}
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                `${item?.town || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>State</td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="county"
                                    id="county"
                                    defaultValue={item?.county}
                                    onKeyDown={validateInput}
                                    placeholder="Enter the county"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.county &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.county}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.county || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Postcode/zip{" "}
                              <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    defaultValue={item?.postcode}
                                    placeholder="Enter the postcode"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.postcode &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.postcode}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.postcode || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Country <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <select
                                    className="form-control w-75"
                                    onChange={handleAddressChange}
                                    defaultValue={item?.country}
                                    name="country"
                                    id="exampleFormControlSelect1"
                                  >
                                    <option value="" selected disabled>
                                      Select Country
                                    </option>
                                    {allCountries?.map((item) => {
                                      return (
                                        <>
                                          <option
                                            key={item?.numcode}
                                            value={item?.printable_name}
                                          >
                                            {item?.name}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </select>
                                  {editError.country &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.country}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.country_name || "--"}`
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Email</td>
                            <td>{item?.email || "---"}</td>
                          </tr>

                          <tr>
                            <td>Status</td>
                            <td>
                              {item?.status === "1" ? (
                                <>
                                  <p className="mb-0 active_">Active</p>
                                </>
                              ) : (
                                <>
                                  <p className="mb-0 inactive_">Deleted</p>
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!orders awating invoice -- invoice 0: transtatus : 0 *!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/} */}

                      {item?.invoiced === "0" && item?.transtatus === "0" && (
                        <>
                          <div className="mb-3">
                            {isEdit && index === editedIndex ? (
                              <>
                                <button
                                  className="mr-2 btn btn-info rounded-0"
                                  id="updateaddressbutton"
                                  onClick={() => updateAddressfun(item)}
                                >
                                  Update Address
                                </button>
                                <button
                                  className="btn btn-danger mx-2 rounded-0"
                                  id="canceleditbutton"
                                  onClick={() => setIsEdit(false)}
                                >
                                  Cancel Edit
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="mr-2 btn btn-info rounded-0"
                                  id="editaddressbutton"
                                  onClick={() =>
                                    handleEditFunctionality(index, item)
                                  }
                                >
                                  Edit Address
                                </button>
                              </>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <>
                                <h6>Pricing Details</h6>

                                <table border="1" className="my-2">
                                  <tr>
                                    <td>Order Total </td>
                                    <td>{item?.total || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Postage and Packaging charge</td>
                                    <td>
                                      <input
                                        type="number"
                                        min="0"
                                        className="inputTag"
                                        defaultValue={item?.postage || ""}
                                        // onKeyDown={validateInp}
                                        name="postage"
                                        id="postage"
                                        onChange={(e) => {
                                          setPostageIndex(item.orderno);
                                          let numberRegEx = /^\d+(\.\d+)?$/;
                                          if (
                                            !(
                                              numberRegEx.test(
                                                e.target.value
                                              ) || e.target.value == ""
                                            )
                                          ) {
                                            setPostageError(true);
                                          } else {
                                            setPostageError(false);
                                            handleChange(e, item.orderno);
                                          }
                                        }}
                                      />
                                      {item.orderno === postageIndex &&
                                        postageError && (
                                          <p className="text-danger">
                                            Positive value only
                                          </p>
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Price Adjustment (+/-)</td>
                                    <td>
                                      <input
                                        type="number"
                                        name="pricechange"
                                        id="pricechange"
                                        defaultValue={item?.pricechange}
                                        className="inputTag"
                                        onChange={(e) =>
                                          handleChange(e, item.orderno)
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Order Total </td>
                                    <td>{item?.grand_total || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Payment Method</td>
                                    <td>{item.paymeth || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Note to buyer (optional) : </td>
                                    <td>
                                      <textarea
                                        name="note"
                                        id="note"
                                        cols="30"
                                        rows="10"
                                        placeholder="Enter note to buyer"
                                        className="inputTextArea"
                                        defaultValue={item?.sellnotes || ""}
                                        onChange={(e) =>
                                          handleChange(e, item.orderno)
                                        }
                                      ></textarea>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Note from buyer : </td>
                                    <td>{item?.buynotes || "--"}</td>
                                  </tr>
                                </table>

                                <div className="my-2">
                                  <button
                                    className="btn btn-primary rounded-0 mr-2"
                                    id="updatepricebutton"
                                    onClick={() => addAdditionalDetails(item)}
                                    disabled={
                                      item.orderno === postageIndex &&
                                        postageError
                                        ? true
                                        : false
                                    }
                                  >
                                    Update Price
                                  </button>
                                  <button
                                    // disabled={(item.pricechange === 0 || item.pricechange === null) && (item.postage === 0 || item.postage === null)}
                                    disabled={isUpdatePriceDisabled(
                                      item?.pricechange,
                                      item?.postage
                                    )}
                                    id="sendinvoicebutton"
                                    className="btn btn-secondary rounded-0 my-2 mr-2"
                                    onClick={() => sendInvoiceFun(item)}
                                  >
                                    Send Invoice
                                  </button>
                                </div>
                              </>
                            </div>

                            <div className="col-md-6">
                              <div className="col-md-6">
                                <div className="generateLabeldiv my-2">
                                  <h6>Generate Label</h6>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <label htmlFor="">Row (cm)</label>

                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="rowInput"
                                        value={rows}
                                        onChange={handleRowChange}
                                      />
                                    </div>

                                    <div className="d-flex flex-column mx-lg-2">
                                      <label htmlFor="">Column (cm)</label>
                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="columnInput"
                                        value={columns}
                                        onChange={handleColumnChange}
                                      />
                                    </div>
                                  </div>
                                  <FunctionalComponentWithHook
                                    rows={rows}
                                    columns={columns}
                                    data={item}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/* orders awating payment -- invoiced 1 : transstatus 0!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
                      {/* {item?.invoiced !== "0" &&
                        <div className="d-flex mb-3">
                          <p><strong>Note from buyer</strong> : </p>
                          <p>{item?.buynotes || "--"}</p>
                        </div>} */}
                      {item?.invoiced === "1" && item?.transtatus === "0" && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <>
                                <div className="my-2">
                                  {isEdit && (
                                    <button
                                      className="mr-2 btn btn-info rounded-0"
                                      id="updateaddressbutton"
                                      onClick={() => updateAddressfun(item)}
                                    >
                                      Update Address
                                    </button>
                                  )}

                                  {isEdit && (
                                    <button
                                      className="btn btn-danger mx-2 rounded-0"
                                      id="canceleditbutton"
                                      onClick={() => setIsEdit(false)}
                                    >
                                      Cancel Edit
                                    </button>
                                  )}

                                  {!isEdit && (
                                    <button
                                      className="mr-2 btn btn-info rounded-0"
                                      id="editaddressbutton"
                                      onClick={() =>
                                        handleEditFunctionality(index, item)
                                      }
                                    >
                                      Edit Address
                                    </button>
                                  )}
                                </div>

                                {item?.invoiced !== "0" &&
                                  <div className="mb-3">
                                    <p><strong>Note from buyer</strong> : <span className="ml-1">{item?.buynotes || "---"}</span></p>
                                    {/* <p><strong>Note from buyer</strong> : </p> */}
                                    {/* <p className="ml-2">{item?.buynotes || "---"}</p> */}
                                  </div>}

                                <h5 className="mb-0 subHeading">
                                  Order Subtotal :£ {item?.total}
                                </h5>

                                <div className="mb-2">
                                  <p className="mb-0">
                                    <strong>Postage and Packaging : </strong>

                                    {item?.postage
                                      ? `£${item?.postage}`
                                      : "---"}
                                  </p>
                                  <p className="mb-1">
                                    <strong>Price Adjustment : </strong>

                                    {item?.pricechange
                                      ? `£${item?.pricechange}`
                                      : "---"}
                                  </p>
                                  <hr className="hr_design" />
                                </div>

                                <h5 className="mb-2 subHeading">
                                  Order Total : £{item?.grand_total || "--"}
                                </h5>

                                <h5 className="mb-0 subHeading">
                                  Payment Method :{item?.paymeth || "--"}
                                </h5>

                                <button
                                  className="btn btn-primary rounded-0 my-2 mr-2"
                                  id="markpaymentreceviedbutton"
                                  onClick={() => markPaymentReceived(item)}
                                >
                                  Mark Payment Received
                                </button>

                                <button
                                  className="btn btn-info rounded-0 my-2 mr-2"
                                  id="sendPaymentReminder"
                                  onClick={() => sendPaymentReminderFun(item)}
                                >
                                  Send Payment Remainder
                                </button>

                                <button
                                  className="btn btn-secondary rounded-0 my-2 mr-2"
                                  id="generateInvoice"
                                  onClick={() => generateInvoicePage(item)}
                                >
                                  View Invoice
                                </button>
                              </>
                            </div>

                            <div className="col-md-6">
                              <div className="generateLabeldiv my-2">
                                <h6>Generate Label</h6>
                                <div className="d-lg-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <label htmlFor="">Row (cm)</label>

                                    <input
                                      type="number"
                                      className="inputTag"
                                      id="rowInput"
                                      value={rows}
                                      onChange={handleRowChange}
                                    />
                                  </div>

                                  <div className="d-flex flex-column mx-lg-2">
                                    <label htmlFor="">Column (cm)</label>
                                    <input
                                      type="number"
                                      className="inputTag"
                                      id="columnInput"
                                      value={columns}
                                      onChange={handleColumnChange}
                                    />
                                  </div>
                                </div>
                                <FunctionalComponentWithHook
                                  rows={rows}
                                  columns={columns}
                                  data={item}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! for paid orders  transtattus 1 : invoiced 1 : order_status : null || "" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}

                      {item?.invoiced === "1" &&
                        item?.transtatus === "1" &&
                        (item.order_status === null ||
                          item.order_status === "" ||
                          item.order_status === "packed" ||
                          item.order_status === "dispatch") && (
                          <>
                            <div>
                              <div className="mb-3">
                                <p><strong>Note from buyer</strong> : <span className="ml-1">{item?.buynotes || "---"}</span></p>
                                {/* <p><strong>Note from buyer</strong> : </p>
                                <p className="ml-2">{item?.buynotes || "---"}</p> */}
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="mb-0 subHeading">
                                    Order Subtotal :£ {item?.total}
                                  </h5>

                                  <div className="mb-2">
                                    <p className="mb-0">
                                      <strong>Postage and Packaging : </strong>

                                      {item?.postage
                                        ? `£${item?.postage}`
                                        : "---"}
                                    </p>
                                    <p className="mb-1">
                                      <strong>Price Adjustment : </strong>

                                      {item?.pricechange
                                        ? `£${item?.pricechange}`
                                        : "---"}
                                    </p>
                                    <hr className="hr_design" />
                                  </div>

                                  <h5 className="mb-2 subHeading">
                                    Order Total : £{item?.grand_total || "--"}
                                  </h5>

                                  <h5 className="mb-0 subHeading">
                                    Payment Method :{item?.paymeth || "--"}
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <div className="generateLabeldiv my-2">
                                    <h6>Generate Label</h6>
                                    <div className="d-lg-flex align-items-center">
                                      <div className="d-flex flex-column">
                                        <label htmlFor="">Row (cm)</label>

                                        <input
                                          type="number"
                                          className="inputTag"
                                          id="rowInput"
                                          value={rows}
                                          onChange={handleRowChange}
                                        />
                                      </div>

                                      <div className="d-flex flex-column mx-lg-2">
                                        <label htmlFor="">Column (cm)</label>
                                        <input
                                          type="number"
                                          className="inputTag"
                                          id="columnInput"
                                          value={columns}
                                          onChange={handleColumnChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <FunctionalComponentWithHook
                                    rows={rows}
                                    columns={columns}
                                    data={item}
                                  />
                                </div>
                              </div>
                            </div>

                            <button
                              className="btn btn-secondary rounded-0 mb-2 mr-2"
                              id="viewinvoicebutton"
                              onClick={() => generateInvoicePage(item)}
                            >
                              View Invoice
                            </button>

                            <div className="paymentstatusdropdown">
                              <div className="d-flex flex-column w-75">
                                <label htmlFor="" className="mb-0">
                                  Delivery Status :
                                </label>

                                <select
                                  id="exampleFormControlSelect1"
                                  name="order_status"
                                  className="form-control inpCategory w-75"
                                  defaultValue={item?.order_status}
                                  onChange={(e) =>
                                    handleDeliveryStatus(e, item?.order_status)
                                  }
                                >
                                  <option value="" selected disabled>
                                    Select Delivery Status
                                  </option>

                                  <option value="packed">Packed</option>
                                  <option value="dispatch">Dispatch</option>
                                  <option value="delivered">Delivered</option>
                                </select>
                              </div>

                              <div className="d-flex flex-column my-2 _deliverynote">
                                <label htmlFor="">Delivery Note</label>
                                <textarea
                                  name="order_note"
                                  id=""
                                  cols="30"
                                  rows="10"
                                  placeholder="Enter the delivery note..."
                                  className="inpTextArea"
                                  defaultValue={item?.order_note || "---"}
                                  onChange={(e) =>
                                    handleDeliveryNote(e, item.orderno)
                                  }
                                ></textarea>
                              </div>
                              <div className="my-2">
                                <button
                                  className="btn btn-info rounded-0"
                                  id="updatestatusbutton"
                                  onClick={() => updateDeliveryStatus(item)}
                                >
                                  Update Status
                                </button>
                              </div>
                            </div>

                            {/* the transaction table */}
                            <div className="w-100">
                              <h6>Transaction Table</h6>
                              <TransactionTable
                                transactiondata={item?.transaction_details}
                              />
                            </div>
                          </>
                        )}

                      {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  for completed orders transtatus 1: invoiced: 1: order status :"status of order" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}

                      {item?.invoiced === "1" &&
                        item?.transtatus === "1" &&
                        item.order_status === "delivered" && (
                          <>
                            <div className="mb-3">
                              <p><strong>Note from buyer</strong> : <span className="ml-1">{item?.buynotes || "---"}</span></p>
                              {/* <p className="ml-2">{item?.buynotes || "---"}</p> */}
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <button
                                  className="btn btn-secondary rounded-0 my-2 mr-2"
                                  id="viewinvoice"
                                  onClick={() => generateInvoicePage(item)}
                                >
                                  View Invoice
                                </button>
                              </div>

                              <div className="col-md-6">
                                <div className="generateLabeldiv my-2">
                                  <h6>Generate Label</h6>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <label htmlFor="">Row (cm)</label>

                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="rowInput"
                                        value={rows}
                                        onChange={handleRowChange}
                                      />
                                    </div>

                                    <div className="d-flex flex-column mx-lg-2">
                                      <label htmlFor="">Column (cm)</label>
                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="columnInput"
                                        value={columns}
                                        onChange={handleColumnChange}
                                      />
                                    </div>
                                  </div>
                                  <FunctionalComponentWithHook
                                    rows={rows}
                                    columns={columns}
                                    data={item}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* the transaction table */}
                            {/* <div className="w-100">
                              <h6>Transaction Table</h6>

                              <TransactionTable
                                transactiondata={
                                  item?.transaction_details || []
                                }
                              />
                            </div> */}
                            <div className="w-100">
                              <h6>Transaction Table</h6>
                              <TransactionTable
                                transactiondata={
                                  item?.transaction_details || []
                                }
                              />
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </>
            );
          }
        })}
      </>

      <div
        className="modal fade"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">
                Delete Order
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                id="deleteordermodalicon"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure you want to delete the order?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                data-dismiss="modal"
                id="closeDeleteModalCustomer"
              >
                Close
              </button>

              <>
                <button
                  type="button"
                  className="btn btn-danger rounded-0"
                  id="deleteorderbutton"
                  onClick={deleteCustomerFun}
                >
                  Delete
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
