import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";
import { TransactionTable } from "../OrderTable/TransactionTable";
import BounceLoader from "react-spinners/BounceLoader";
import { configURL } from "../../../../Services/configuration";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";
import { FunctionalComponentWithHook } from "../../CustomerManagement/ViewCustomer/FunctionalComponentWithHook";

export const ListingPackedOrders = ({
  data,
  getAllDataForTheSelectedFilter,
}) => {
  const { getRequest, postRequest } = ApiResponseHook();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [allCountries, setAllCountries] = useState([]);
  const [unpackNum, setUnpackNum] = useState("");
  const [deliveryStatus, setDeiveryStatus] = useState("");
  const [deliveryNote, setDeliveryNote] = useState("");
  // let [loading, setLoading] = useState(false);

  const [editAddress, setEditAddress] = useState({
    address1: "",
    address2: "",
    town: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });
  const [editError, setEditError] = useState({
    address1: false,
    address2: false,
    town: false,
    county: false,
    postcode: false,
    country: false,
    orderno: false,
  });
  const [editErrorMsg, setEditErrorMsg] = useState({
    address1: "",
    address2: "",
    town: "",
    county: "",
    postcode: "",
    country: "",
    orderno: "",
  });

  const [updateOrder, setUpdateOrder] = useState({});

  const [postageError, setPostageError] = useState(false);
  const [postageIndex, setPostageIndex] = useState(null);

  const [rows, setRows] = useState(1);
  const [columns, setColumns] = useState(1);
  const handleRowChange = (e) => {
    const value = e.target.value;
    setRows(isNaN(value) ? null : value);
  };

  const handleColumnChange = (e) => {
    const value = e.target.value;
    setColumns(isNaN(value) ? null : value);
  };

  // useEffect(() => {
  //   getAllCountries();
  // }, []);
  useEffect(() => {
    setIsEdit(false)
  }, [data]);

  const getAllCountries = async () => {
    // let res = await GetAllCountriesService();
    let res = await getRequest(configURL.get_all_countries);

    if (res?.status === 200 || res?.status === 201) {
      setAllCountries(res?.data?.data);
    } else {
      // console.log("error getting all the countries", res);
    }
  };

  function validateInput(event) {
    const inputValue = event.key;
    if (/[0-9]/.test(inputValue)) {
      event.preventDefault();
    }
  }

  const handleChange = (e, index) => {
    let { name, value } = e.target;

    if (name === "postage") {
      const inputValue = parseFloat(value);
      if (isNaN(inputValue) || inputValue < 0) {
        e.target.value = ""; // Clear the input
      } else {
        setUpdateOrder({
          ...updateOrder,
          [`postage${index}`]: value || "0",
        });
      }
    }
    if (name === "pricechange") {
      const inputValue = value;
      const numberPattern = /^-?\d*\.?\d*$/;
      if (!numberPattern.test(inputValue)) {
        // Prevent the input
        e.preventDefault();
      } else {
        setUpdateOrder({
          ...updateOrder,
          [`pricechange${index}`]: value || "0",
        });
      }
    }
    if (name === "note") {
      setUpdateOrder({
        ...updateOrder,
        [`note${index}`]: value,
      });
    }
  };

  const handleAddressChange = (e) => {
    let { name, value } = e.target;

    if (name === "address1") {
      setEditError({
        ...editError,
        address1: false,
      });
      setEditAddress({
        ...editAddress,
        address1: value,
      });
    }
    if (name === "address2") {
      setEditError({
        ...editError,
        address2: false,
      });
      setEditAddress({
        ...editAddress,
        address2: value,
      });
    }
    if (name === "town") {
      setEditError({
        ...editError,
        town: false,
      });
      setEditAddress({
        ...editAddress,
        town: value,
      });
    }
    if (name === "county") {
      // setEditError({
      //   ...editError,
      //   county: false,
      // });
      setEditAddress({
        ...editAddress,
        county: value,
      });
    }
    if (name === "postcode") {
      setEditError({
        ...editError,
        postcode: false,
      });
      setEditAddress({
        ...editAddress,
        postcode: value,
      });
    }
    if (name === "country") {
      setEditError({
        ...editError,
        country: false,
      });
      setEditAddress({
        ...editAddress,
        country: value,
      });
    }
  };

  // get particular orderno price change values
  const getPostageValue = (data, number, keyName) => {
    const key = keyName + number;
    return data[key];
  };

  const addAdditionalDetails = async (data) => {
    let payload = {
      postage_charge:
        getPostageValue(updateOrder, data.orderno, "postage") || data.postage,
      adjustment:
        getPostageValue(updateOrder, data.orderno, "pricechange") ||
        data.pricechange,
      note: getPostageValue(updateOrder, data.orderno, "note") || "",
      package_id: data.orderno,
    };

    // let payload = {
    //   postage_charge: updateOrder.postage || data.postage,
    //   adjustment: updateOrder.pricechange || data.pricechange,
    //   note: updateOrder.note === "" ? data.sellnote : updateOrder.note,
    //   package_id: data.orderno,
    // };

    // setLoading(true);
    // let res = await AddPakcedAdditionalOrderService(payload);
    let res = await postRequest(
      `${configURL.add_postage_price_change_packed}`,
      payload
    );

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Updated the price successfully`,
      });

      getAllDataForTheSelectedFilter();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const handleEditFunctionality = (index, item) => {
    if (allCountries.length < 1) {
      getAllCountries()
    }
    setEditedIndex(index);
    setIsEdit(true);
    setEditAddress({
      address1: item?.address1,
      address2: item?.address2,
      country: item?.country,
      county: item?.county,
      orderno: item?.orderno,
      postcode: item?.postcode,
      town: item?.town,
    });
  };

  const sendInvoiceFun = async (data) => {
    // setLoading(true);

    // let res = await SendPackedOrderInvoiceService(data.orderno);
    let res = await getRequest(
      `${configURL.sendInvoice_packed_order}/${data.orderno}/`
    );

    if (res.status === 201 || res.status === 200) {
      // setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Invoice sent successfully!`,
      });
      getAllDataForTheSelectedFilter();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // setLoading(false);
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const markPaymentReceived = async (data) => {
    // setLoading(true);
    // let res = await PackedOrderMarkPaymentReceviedServcie(data?.orderno);
    let res = await getRequest(
      `${configURL.mark_payment_received_packed_order}/${data?.orderno}/`
    );

    if (res?.status === 201 || res?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Marked Payment Received`,
      });
      getAllDataForTheSelectedFilter();
      // setLoading(false);
    } else if (
      res?.response?.status === 400 ||
      res?.response?.status === 401 ||
      res?.response?.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
      // setLoading(false);
    } else {
      // console.log("errror", res);
      // setLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const sendPaymentReminderFun = async (data) => {
    // setLoading(true);
    // let res = await SendPaymentRemainderPackedService(data?.orderno);

    let res = await getRequest(
      `${configURL.send_payment_remainder_packed}/${data?.orderno}/`
    );

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Remainder sent successfully!`,
      });
      getAllDataForTheSelectedFilter();
      // setLoading(false);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
      // setLoading(false);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      // setLoading(false);
    }
  };

  const generateInvoicePage = (data) => {
    let customerDetails = {
      name: data?.name,
      address1: data?.address1,
      address2: data?.address2,
      town: data?.town,
      county: data?.county,
      postcode: data?.postcode,
      country: data?.country,
      email: data?.email,
    };

    navigate(`/${RouteStrings.orderPackedInvoicePage}`, {
      state: { data: { order: data, customer: customerDetails } },
    });
  };

  const handleDeliveryStatus = (e, order_status) => {
    setDeiveryStatus(e.target.value);
  };

  const updateDeliveryStatus = async (data) => {
    let _package_note = "";
    let _package_status = "";

    let pakagenote_data =
      getPostageValue(deliveryNote, data.orderno, "delivery") || "";

    if (deliveryStatus === "") {
      _package_status = data?.package_status;
    } else {
      _package_status = deliveryStatus;
    }

    _package_note = pakagenote_data;

    let payload = {
      package_status: _package_status,
      package_note:
        getPostageValue(deliveryNote, data.orderno, "delivery") || "",
      package_id: data?.orderno,
    };

    if (
      (payload.package_status === "" ||
        payload.package_status === undefined ||
        payload.package_status === null) &&
      (payload.package_note === "" ||
        payload.package_note === undefined ||
        payload.package_note === null)
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please enter the status and the note`,
      });
    } else if (
      payload.package_note === "" ||
      payload.package_note === undefined ||
      payload.package_note === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please enter the note`,
      });
    } else if (
      payload.package_status === "" ||
      payload.package_status === undefined ||
      payload.package_status === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `please select the status`,
      });
    } else {
      // let res = await ChangePackedOrderStatusService(paylaod);
      let res = await postRequest(configURL.changepackedorderstatus, payload);

      if (res?.status === 200 || res?.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Delivery Status Updated`,
        });
        getAllDataForTheSelectedFilter();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${res?.response?.data?.message} || error updating the delivery status`,
        });
      }
    }
  };

  const updateCustomerAddress = async (data) => {
    if (
      data.address1 === "" &&
      editAddress.address1 === "" &&
      editAddress.town === "" &&
      editAddress.country === "" &&
      editAddress.postcode === ""
    ) {
      setEditError({
        ...editError,
        address1: true,
        town: true,
        country: true,
        postcode: true,
      });

      setEditErrorMsg({
        address1: "Please enter address 1",
        town: "Please enter the town",
        country: "Please enter country",
        postcode: "Please enter postcode",
      });
    } else if (editAddress.address1 === "") {
      setEditError({
        editError,
        address1: true,
      });
      setEditErrorMsg({
        address1: "Please enter address 1",
      });
    } else if (editAddress.town === "") {
      setEditError({
        editError,
        town: true,
      });
      setEditErrorMsg({
        town: "Please enter town",
      });
    } else if (editAddress.country === "") {
      setEditError({
        editError,
        country: true,
      });
      setEditErrorMsg({
        country: "Please enter country",
      });
    } else if (editAddress.postcode === "") {
      setEditError({
        editError,
        postcode: true,
      });
      setEditErrorMsg({
        postcode: "Please enter postcode",
      });
    } else {
      let payload = {
        address1:
          editAddress.address1 !== "" ? editAddress?.address1 : data.address1,
        address2:
          editAddress.address2 !== "" ? editAddress?.address2 : data.address2,
        city: editAddress.town !== "" ? editAddress?.town : data.town,
        county: editAddress.county !== "" ? editAddress?.county : data.county,
        postcode:
          editAddress.postcode !== "" ? editAddress?.postcode : data.postcode,
        country:
          editAddress.country !== "" ? editAddress.country : data.country,
        package_id: data.orderno,
      };

      // let res = await EditPakcedCustomerAddressService(payload);
      let res = await postRequest(
        configURL.add_additional_packed_order,
        payload
      );

      if (res?.status === 201 || res?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `Updated the address successfully`,
        });
        getAllDataForTheSelectedFilter();

        setIsEdit(false);
      } else if (
        res?.response?.status === 400 ||
        res?.response?.status === 401 ||
        res?.response?.status === 404 ||
        res?.response?.status === 500
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res?.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const unpackOrdersfunction = async () => {
    // let res = await UnpackedOrdersService(unpackNum);
    let res = await getRequest(`${configURL.unpacke_orders}/${unpackNum}/`);
    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Unpacked the order successfully`,
      });
      document.getElementById("closeunpackOrderModal").click();
      getAllDataForTheSelectedFilter();
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      // console.log("errorr", res);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      document.getElementById("closeunpackOrderModal").click();
    } else {
      // console.log("errorr", res);

      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
      document.getElementById("closeunpackOrderModal").click();
    }
  };

  const getOnlyDateFromDateTime = (date_) => {
    const parts = date_.split(" ");

    const datePart = parts[0];

    return datePart;
  };

  const handleDeliveryNote = (e, orderno) => {
    let { name, value } = e.target;
    if (value) {
      setDeliveryNote({
        ...deliveryNote,
        [`delivery${orderno}`]: value || "0",
      });
    }
  };

  const isUpdatePriceDisabled = (price, postage) => {
    if (price === null) {
      return "true";
    } else if (postage === null) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      {data?.length === 0 && (
        <>
          <strong>No orders found</strong>
        </>
      )}

      <>
        {data?.map((item, index) => {
          if (item.status === "1") {
            return (
              <>
                <div className="my-4">
                  <div className="card p-2 order_card">
                    <div className="ordernumber mb-2 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6>Package Id : {item?.orderno}</h6>
                      </div>

                      <>
                        {item?.invoiced === "0" && item?.transtatus === "0" && (
                          <>
                            <div>
                              <button
                                className="btn btn-danger rounded-0"
                                id="uppackorderbuttonmodal"
                                data-toggle="modal"
                                data-target="#unpackModal"
                                onClick={() => setUnpackNum(item.orderno)}
                              >
                                Unpack Order
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    </div>

                    <div>
                      <h6>Items here packed</h6>

                      {item?.orders?.map((order) => {
                        return (
                          <>
                            <div className="card p-2 my-2 ">
                              <div className="d-flex flex-column">
                                <h6>Order Number : {order?.orderno}</h6>
                                <h6>
                                  Order Date : {/* {order?.ordate} */}
                                  {getOnlyDateFromDateTime(order?.ordate) ||
                                    "---"}
                                </h6>
                              </div>

                              {/* items in the order */}
                              {order?.order_item?.map((stock) => {
                                return (
                                  <>
                                    <div className="row ">
                                      <div className="col-md-4">
                                        <div className="image_div">
                                          <img
                                            src={stock?.stockimages[0]}
                                            alt={`${stock?.author} ${stock?.title}`}
                                            className="stockimage my-2"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <div>
                                          <p>{stock?.author}</p>
                                          <p>{stock?.title}</p>
                                          <p>{stock?.description}</p>
                                          <p>{stock?.stockref}</p>
                                          <p>{stock?.location}</p>
                                          <p>£{stock?.price}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        );
                      })}
                    </div>

                    {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! the customer detials here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

                    <div className="customerdetails">
                      <h6>Customer Details</h6>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Name</td>
                            <td>{item?.name || "--"}</td>
                          </tr>
                          <tr>
                            <td>Customer Number</td>
                            <td>{item?.custno || "--"}</td>
                          </tr>
                          <tr>
                            <td>
                              Address Line 1{" "}
                              <span className="text-danger">&#42;</span>
                            </td>
                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="address1"
                                    id="address1"
                                    defaultValue={item?.address1}
                                    onChange={handleAddressChange}
                                    placeholder="Enter the address1"
                                    className="inputTag w-75"
                                  />
                                  {editError.address1 &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.address1}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.address1 || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr key={index}>
                            <td>Address Line 2</td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="address2"
                                    id="address2"
                                    defaultValue={item?.address2}
                                    placeholder="Enter the address2"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.address2 &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.address2}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.address2 || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Town/City
                              <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="town"
                                    id="town"
                                    defaultValue={item?.town}
                                    placeholder="Enter the town"
                                    onKeyDown={validateInput}
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.town && index === editedIndex && (
                                    <>
                                      <p className="mb-0 text-danger">
                                        {editErrorMsg.town}
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                `${item?.town || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>State</td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="county"
                                    id="county"
                                    defaultValue={item?.county}
                                    onKeyDown={validateInput}
                                    placeholder="Enter the county"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.county &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.county}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.county || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Postcode/zip{" "}
                              <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    defaultValue={item?.postcode}
                                    placeholder="Enter the postcode"
                                    onChange={handleAddressChange}
                                    className="inputTag w-75"
                                  />
                                  {editError.postcode &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.postcode}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.postcode || "--"}`
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Country <span className="text-danger">&#42;</span>
                            </td>

                            <td>
                              {isEdit && index === editedIndex ? (
                                <>
                                  <select
                                    className="form-control w-75"
                                    onChange={handleAddressChange}
                                    defaultValue={item?.country}
                                    name="country"
                                    id="exampleFormControlSelect1"
                                  >
                                    <option value="" selected disabled>
                                      Select Country
                                    </option>
                                    {allCountries?.map((item) => {
                                      return (
                                        <>
                                          <option
                                            key={item?.numcode}
                                            value={item?.printable_name}
                                          >
                                            {item?.name}
                                          </option>
                                        </>
                                      );
                                    })}
                                  </select>
                                  {editError.country &&
                                    index === editedIndex && (
                                      <>
                                        <p className="mb-0 text-danger">
                                          {editErrorMsg.country}
                                        </p>
                                      </>
                                    )}
                                </>
                              ) : (
                                `${item?.country_name || "--"}`
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Email</td>
                            <td>{item?.email || "---"}</td>
                          </tr>

                          <tr>
                            <td>Status</td>
                            <td>
                              {item?.status === "1" ? (
                                <>
                                  <p className="mb-0 active_">Active</p>
                                </>
                              ) : (
                                <>
                                  <p className="mb-0 inactive_">Deleted</p>
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {/* action div below here */}

                      {/* {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!orders awating invoice -- invoice 0: transtatus : 0 *!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!/} */}

                      {item?.invoiced === "0" && item?.transtatus === "0" && (
                        <>
                          <div className="mb-3">
                            {isEdit && index === editedIndex ? (
                              <>
                                <button
                                  className="mr-2 btn btn-info rounded-0"
                                  id="udpateaddress"
                                  onClick={() => updateCustomerAddress(item)}
                                >
                                  Update Address
                                </button>
                                <button
                                  className="btn btn-danger mx-2 rounded-0"
                                  id="canceledit"
                                  onClick={() => setIsEdit(false)}
                                >
                                  Cancel Edit
                                </button>
                              </>
                            ) : (
                              <div>
                                <button
                                  className="mr-2 btn btn-info rounded-0"
                                  id="editaddressbutton"
                                  onClick={() =>
                                    handleEditFunctionality(index, item)
                                  }
                                >
                                  Edit Address
                                </button>
                              </div>
                            )}
                          </div>

                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <h6>Pricing Details</h6>

                                <table border="1" className="my-2">
                                  <tr>
                                    <td>Order Total </td>
                                    <td>{item?.total || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Postage and Packaging charge</td>
                                    <td>
                                      <input
                                        type="number"
                                        min="0"
                                        className="inputTag"
                                        defaultValue={item?.postage || ""}
                                        // onKeyDown={validateInp}
                                        name="postage"
                                        id="postage"
                                        // onChange={(e) => handleChange(e)}
                                        onChange={(e) => {
                                          setPostageIndex(item.orderno);
                                          let numberRegEx = /^\d+(\.\d+)?$/;
                                          if (
                                            !(
                                              numberRegEx.test(
                                                e.target.value
                                              ) || e.target.value == ""
                                            )
                                          ) {
                                            setPostageError(true);
                                          } else {
                                            setPostageError(false);
                                            handleChange(e, item.orderno);
                                          }
                                        }}
                                      />
                                      {item.orderno === postageIndex &&
                                        postageError && (
                                          <p className="text-danger">
                                            Positive value only
                                          </p>
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Price Adjustment (+/-)</td>
                                    <td>
                                      <input
                                        type="number"
                                        name="pricechange"
                                        id="pricechange"
                                        defaultValue={item?.pricechange}
                                        className="inputTag"
                                        onChange={(e) =>
                                          handleChange(e, item.orderno)
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Order Total </td>
                                    <td>{item?.grand_total || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Payment Method</td>
                                    <td>{item.paymeth || "--"}</td>
                                  </tr>
                                  <tr>
                                    <td>Note to buyer (optional) : </td>
                                    <td>
                                      <textarea
                                        name="note"
                                        id="note"
                                        cols="30"
                                        rows="10"
                                        placeholder="Enter note to buyer"
                                        className="inputTextArea"
                                        defaultValue={item?.sellnote || ""}
                                        onChange={(e) =>
                                          handleChange(e, item.orderno)
                                        }
                                      ></textarea>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Note from buyer : </td>
                                    <td>
                                      {item?.orders?.map((order) => {
                                        return (
                                          <>
                                            <div className="row">
                                              <div className="col-md-4">
                                                <strong>Order No :</strong>

                                                {order?.orderno}
                                              </div>
                                              <div className="col-md-8">
                                                <strong>Note :</strong>
                                                {order?.buynotes || "---"}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </td>
                                  </tr>
                                </table>
                              </div>

                              <div className="col-md-6">
                                <div className="generateLabeldiv my-2">
                                  <h6>Generate Label</h6>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <label htmlFor="">Row (cm)</label>

                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="rowInput"
                                        value={rows}
                                        onChange={handleRowChange}
                                      />
                                    </div>

                                    <div className="d-flex flex-column mx-lg-2">
                                      <label htmlFor="">Column (cm)</label>
                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="columnInput"
                                        value={columns}
                                        onChange={handleColumnChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <FunctionalComponentWithHook
                                  rows={rows}
                                  columns={columns}
                                  data={item}
                                />
                              </div>
                            </div>
                          </>

                          <div className="d-flex">
                            <button
                              className="btn btn-primary rounded-0 mr-2 my-2"
                              id="updatepricebutton"
                              onClick={() => addAdditionalDetails(item)}
                              disabled={
                                item?.orderno === postageIndex && postageError
                                  ? true
                                  : false
                              }
                            >
                              Update Price
                            </button>
                            <button
                              // disabled="true"
                              disabled={isUpdatePriceDisabled(
                                item?.pricechange,
                                item?.postage
                              )}
                              className="btn btn-secondary rounded-0 my-2 mr-2"
                              id="sendinvoicebutton"
                              onClick={() => sendInvoiceFun(item)}
                            >
                              Send Invoice
                            </button>
                          </div>
                        </>
                      )}

                      {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! orders awating payment -- invoiced 1 : transstatus 0!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

                      {item?.invoiced === "1" && item?.transtatus === "0" && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <>
                                <div className="my-2">
                                  {isEdit && (
                                    <button
                                      className="mr-2 btn btn-info rounded-0"
                                      id="upateaddressbutton"
                                      onClick={() =>
                                        updateCustomerAddress(item)
                                      }
                                    >
                                      Update Address
                                    </button>
                                  )}

                                  {isEdit && (
                                    <button
                                      className="btn btn-danger rounded-0"
                                      id="canceleditaddressbutton"
                                      onClick={() => setIsEdit(false)}
                                    >
                                      Cancel Edit
                                    </button>
                                  )}

                                  {!isEdit && (
                                    <button
                                      className="mr-2 btn btn-info rounded-0"
                                      id="editaddressbutton"
                                      onClick={() =>
                                        handleEditFunctionality(index, item)
                                      }
                                    >
                                      Edit Address
                                    </button>
                                  )}
                                </div>

                                <h5 className="mb-0 subHeading">
                                  Order Subtotal :£ {item?.total}
                                </h5>

                                <div className="my-2">
                                  <p className="mb-0">
                                    <strong>Postage and Packaging : </strong>

                                    {item?.postage
                                      ? `£${item?.postage}`
                                      : "---"}
                                  </p>
                                  <p className="mb-0">
                                    <strong>Price Adjustment : </strong>

                                    {item?.pricechange
                                      ? `£${item?.pricechange}`
                                      : "---"}
                                  </p>
                                </div>

                                <h5 className="mb-0 subHeading">
                                  Order Total : £{item?.grand_total || "--"}
                                </h5>

                                <h5 className="mb-0 subHeading">
                                  Payment Method :{item?.paymeth || "--"}
                                </h5>

                                <button
                                  className="btn btn-primary rounded-0 my-2 mr-2"
                                  id="markPaymentReceived"
                                  onClick={() => markPaymentReceived(item)}
                                >
                                  Mark Payment Received
                                </button>

                                <button
                                  className="btn btn-info rounded-0 my-2 mr-2"
                                  id="sendPaymentReminder"
                                  onClick={() => sendPaymentReminderFun(item)}
                                >
                                  Send Payment Remainder
                                </button>

                                <button
                                  className="btn btn-secondary rounded-0 my-2 mr-2"
                                  id="generateInvoice"
                                  onClick={() => generateInvoicePage(item)}
                                >
                                  View Invoice
                                </button>
                              </>
                            </div>

                            <div className="col-md-6">
                              <div className="generateLabeldiv my-2">
                                <h6>Generate Label</h6>
                                <div className="d-lg-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <label htmlFor="">Row (cm)</label>

                                    <input
                                      type="number"
                                      className="inputTag"
                                      id="rowInput"
                                      value={rows}
                                      onChange={handleRowChange}
                                    />
                                  </div>

                                  <div className="d-flex flex-column mx-lg-2">
                                    <label htmlFor="">Column (cm)</label>
                                    <input
                                      type="number"
                                      className="inputTag"
                                      id="columnInput"
                                      value={columns}
                                      onChange={handleColumnChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <FunctionalComponentWithHook
                                rows={rows}
                                columns={columns}
                                data={item}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! for paid orders  transtattus 1 : invoiced 1 : order_status : null || "" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}

                      {item?.invoiced === "1" &&
                        item.transtatus === "1" &&
                        (item?.package_status === null ||
                          item?.package_status === "" ||
                          item?.package_status === "packed" ||
                          item?.package_status === "dispatch") && (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="my-2">
                                  <h5 className="mb-0 subHeading">
                                    Order Subtotal :£ {item?.total}
                                  </h5>

                                  <div className="my-2">
                                    <p className="mb-0">
                                      <strong>Postage and Packaging : </strong>

                                      {item?.postage
                                        ? `£${item?.postage}`
                                        : "---"}
                                    </p>
                                    <p className="mb-0">
                                      <strong>Price Adjustment : </strong>

                                      {item?.pricechange
                                        ? `£${item?.pricechange}`
                                        : "---"}
                                    </p>
                                  </div>

                                  <h5 className="mb-0 subHeading">
                                    Order Total : £{item?.grand_total || "--"}
                                  </h5>

                                  <h5 className="mb-0 subHeading">
                                    Payment Method :{item?.paymeth || "--"}
                                  </h5>
                                </div>

                                <button
                                  className="btn btn-secondary rounded-0 my-2 mr-2"
                                  id="viewinvoicebutton"
                                  onClick={() => generateInvoicePage(item)}
                                >
                                  View Invoice
                                </button>
                              </div>
                              <div className="col-md-6">
                                <div className="generateLabeldiv my-2">
                                  <h6>Generate Label</h6>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <label htmlFor="">Row (cm)</label>

                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="rowInput"
                                        value={rows}
                                        onChange={handleRowChange}
                                      />
                                    </div>

                                    <div className="d-flex flex-column mx-lg-2">
                                      <label htmlFor="">Column (cm)</label>
                                      <input
                                        type="number"
                                        className="inputTag"
                                        id="columnInput"
                                        value={columns}
                                        onChange={handleColumnChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <FunctionalComponentWithHook
                                  rows={rows}
                                  columns={columns}
                                  data={item}
                                />
                              </div>
                            </div>
                            <div className="w-75">
                              <div className="paymentstatusdropdown">
                                <div className="d-flex flex-column w-75">
                                  <label htmlFor="" className="mb-0">
                                    Delivery Status :
                                  </label>

                                  <select
                                    className="form-control inpCategory w-75"
                                    onChange={(e) =>
                                      handleDeliveryStatus(
                                        e,
                                        item?.package_status
                                      )
                                    }
                                    defaultValue={item?.package_status}
                                    name="package_status"
                                    id="exampleFormControlSelect1"
                                  >
                                    <option value="" selected disabled>
                                      Select Delivery Status
                                    </option>

                                    <option value="packed">Packed</option>
                                    <option value="dispatch">Dispatch</option>
                                    <option value="delivered">Delivered</option>
                                  </select>
                                </div>

                                <div className="d-flex flex-column my-2 _deliverynote">
                                  <label htmlFor="">Delivery Note</label>
                                  <textarea
                                    name="package_note"
                                    id=""
                                    cols="30"
                                    rows="10"
                                    placeholder="Enter the delivery note..."
                                    className="inpTextArea"
                                    defaultValue={item?.package_note}
                                    onChange={(e) =>
                                      handleDeliveryNote(e, item.orderno)
                                    }
                                  ></textarea>
                                </div>
                                <div className="my-2">
                                  <button
                                    className="btn btn-info rounded-0"
                                    id="updatestatusbutton"
                                    onClick={() => updateDeliveryStatus(item)}
                                  >
                                    Update Status
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* the transaction table */}
                            <div className="w-100 my-2">
                              <h6>Transaction Table packed</h6>

                              <TransactionTable
                                transactiondata={item?.transaction_details}
                              />
                            </div>
                          </>
                        )}

                      {/*//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!  for completed orders transtatus 1: invoiced: 1: order status :"status of order" !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}

                      {item?.invoiced === "1" &&
                        item?.transtatus === "1" &&
                        item.package_status === "delivered" && (
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <button
                                  className="btn btn-secondary rounded-0 my-2 mr-2"
                                  id="viewinvoicebutton"
                                  onClick={() => generateInvoicePage(item)}
                                >
                                  View Invoice
                                </button>
                              </div>
                              <div className="col-md-6">
                                <div className="col-md-6">
                                  <div className="generateLabeldiv my-2">
                                    <h6>Generate Label</h6>
                                    <div className="d-lg-flex align-items-center">
                                      <div className="d-flex flex-column">
                                        <label htmlFor="">Row (cm)</label>

                                        <input
                                          type="number"
                                          className="inputTag"
                                          id="rowInput"
                                          value={rows}
                                          onChange={handleRowChange}
                                        />
                                      </div>

                                      <div className="d-flex flex-column mx-lg-2">
                                        <label htmlFor="">Column (cm)</label>
                                        <input
                                          type="number"
                                          className="inputTag"
                                          id="columnInput"
                                          value={columns}
                                          onChange={handleColumnChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <FunctionalComponentWithHook
                                    rows={rows}
                                    columns={columns}
                                    data={item}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="w-100 my-2">
                              <h6>Transaction Table</h6>
                              <TransactionTable
                                transactiondata={item?.transaction_details}
                              />
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </>
            );
          }
        })}
      </>

      {/* unpack modal */}
      <div
        className="modal fade"
        id="unpackModal"
        tabindex="-1"
        aria-labelledby="unpackModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="unpackModalLabel">
                Unpack Orders
              </h5>
              <button
                type="button"
                id="unpackordermodalbutton"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure you want to unpack the order?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary rounded-0"
                data-dismiss="modal"
                id="closeunpackOrderModal"
              >
                Close
              </button>

              <>
                <button
                  type="button"
                  className="btn btn-danger rounded-0"
                  id="unpackorderbutton"
                  onClick={unpackOrdersfunction}
                >
                  Unpack
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
